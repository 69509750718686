'use strict';

const constant = require('./constants');
const sessionHelper = require('./sessionHelper');

// Hide header, footer etx for cases when user open ring builders (or any other pages) via Proximity app (client telling app hosted on Service Cloud).
module.exports = {
    hideUiElements: function () {
        const selectors = ['header', 'footer', '.promotional-drawer__wrapper',
            '.payments-pdp', '.delivery-options-wrapper', '.schedule-appointment-wrapper', '#promotionBanner'];

        const isUserAgentIsProximity = sessionHelper.isUserAgentIsProximity();

        // If the user agent is a proximity user agent - hide all page components.
        if (isUserAgentIsProximity) {
            selectors.forEach(function (selector) {
                $(selector).addClass(constant.PROXIMITY_DISPLAY_NONE_CLASS);
            })
        } else {
            selectors.forEach(function (selector) {
                $(selector).removeClass(constant.PROXIMITY_DISPLAY_NONE_CLASS);
            })
        }
    }
};
