'use strict';

/**
 * Initializes select2 fields on the page.
 *
 * This function applies select2 to all select elements on the page,
 * and configures each select element with a placeholder if available.
 *
 */
function initSelect2Field() {
    // Select all select elements except those with the .js-add-item-dropdown class
    $('select:not(.js-add-item-dropdown)').each(function () {
        const select2Config = {
            minimumResultsForSearch: -1
        };

        // Get placeholder from data attribute if available
        const placeholder = $(this).data('placeholder');

        // If a placeholder is set in the data attribute, add it to the config
        if (placeholder) {
            select2Config.placeholder = placeholder;
        }

        // Initialize select2 with the configuration
        $(this).select2(select2Config);
    });
}

module.exports = function () {
    initSelect2Field();

    $('body').on('selectFieldReinit', function () {
        initSelect2Field();
    });
};
