'use strict';

var createErrorNotification = require('base/components/errorNotification');

module.exports = {
    init: function () {
        let eventMethod = 'attachEvent';
        let messageEvent = 'onmessage';
        if (window.addEventListener) {
            eventMethod = 'addEventListener';
            messageEvent = 'message';
        }
        let eventer = window[eventMethod];

        // Listen to message from child window
        eventer(messageEvent, function (e) {
            let key = 'data';
            if (e.message) {
                key = 'message';
            }

            const data = e[key];
            // parse message as json and make sure valid json
            if (data && data !== 'undefined') {
                var json;
                try {
                    json = JSON.parse(data);
                    if (json && json.configuration_id) {
                        // make ajax call to Cart-AddDLGProduct with json as body of post
                        var productContainer = $('.product-detail');
                        if (productContainer && productContainer.data('action') && productContainer.data('action') !== 'undefined') {
                            $.ajax({
                                url: productContainer.data('action'),
                                method: 'POST',
                                data: { payload: data },
                                success: function (response) {
                                    // on success redirect window to Cart-Show
                                    if (response.success && response.redirectUrl) {
                                        window.location.href = response.redirectUrl;
                                    } else {
                                        createErrorNotification($('.error-messaging'), response.message);
                                    }
                                },
                                error: function (err) {
                                    if (err.responseJSON.redirectUrl) {
                                        window.location.href = err.responseJSON.redirectUrl;
                                    } else {
                                        createErrorNotification($('.error-messaging'), err.responseJSON.message);
                                    }
                                }
                            });
                        }
                    }
                } catch (error) {} // eslint-disable-line
            }
        });
    }
};
