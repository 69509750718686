/* eslint-disable valid-jsdoc */
'use strict';
/**
 * Add to bag events
 *
 * @param {function} callback - callback function
 */
function addToBag() {
    $('body').on('product:afterAddToCart', function (e, product) {
        var products = [];
        try {
            if (product && product.cart && product.cart.items) {
                product.cart.items.forEach(function (item) {
                    var product = {};
                    product.sku = item.id;
                    product.quantity = item.quantity;
                    product.price = item.price.sales.value;
                    products.push(product);
                });
                var syteDataLayer = [];
                if ('syteDataLayer' in window && window.syteDataLayer) {
                    syteDataLayer = window.syteDataLayer;
                } else {
                    // make it global for Syte to catch object change
                    window.syteDataLayer = syteDataLayer;
                }
                syteDataLayer.push({
                    name: 'products_added_to_cart',
                    tag: 'ecommerce',
                    products: products
                });
            }
        } catch (e) {
            window.console.log(e);
        }
    });
}

module.exports = {
    addToBag: addToBag
};
