'use strict';

// to be consistent with bootstrap
const adjustment = 0.02;

// see scss/_variables.scss
const breakpoints = {
    get xs() { return 0; },
    get sm() { return (576 - adjustment); },
    get md() { return (992 - adjustment); },
    get lg() { return (1025 - adjustment); },
    get llg() { return (1200 - adjustment); },
    get xl() { return (1600 - adjustment); }
};

module.exports = {
    mobileSm: function () {
        return window.innerWidth && window.innerWidth <= breakpoints.sm;
    },

    mobile: function () {
        return window.innerWidth && window.innerWidth <= breakpoints.md;
    },

    tablet: function () {
        return window.innerWidth && window.innerWidth <= breakpoints.lg;
    },

    desktop: function () {
        return window.innerWidth && window.innerWidth > breakpoints.lg;
    },

    iOS: function () {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },

    macSafari: function () {
        const mac = navigator.appVersion.indexOf('Mac') !== -1;
        const safari = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
        return mac && safari;
    }
};
