require('@babel/polyfill');
require('url-search-params-polyfill');
window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');
var placements = require('int_comenity/comenity/placements');
require('jquery-ui/ui/widgets/datepicker');
require('select2/dist/js/select2.min.js');

$(document).ready(function () {
    var namespace = window.pageContext && window.pageContext.ns ? window.pageContext.ns : null;
    require('./components/tagManager').init(namespace);

    processInclude(require('org/components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('org/components/consentTracking'));
    processInclude(require('org/components/footer'));
    processInclude(require('org/components/carousels'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('syte_sfra/syte/syteDatalayer'));
    processInclude(require('pixleesfra/pixlee/events'));
    processInclude(require('./storeLocator/preferredStore'));
    processInclude(require('int_comenity/comenity/breadPayments'));
    processInclude(require('./components/vimeo'));
    processInclude(require('./components/scrollBarWidth'));
    processInclude(require('./components/promotionalDrawer'));
    processInclude(require('plugin_blog/components/carousels'));
    processInclude(require('plugin_blog/components/video'));
    processInclude(require('./product/dlg'));
    processInclude(require('./components/listbox'));
    processInclude(require('./components/ada'));
    processInclude(require('./product/wishlistHeart'));
    processInclude(require('org/components/accountMobileNavigation'));
    processInclude(require('org/account/getItemsQty'));
    processInclude(require('plugin_proximity/proximityHide'));
    processInclude(require('org/components/selectField'));
    processInclude(require('org/components/responsiveBackground'));
    processInclude(require('org/components/videoEvents'));

    setTimeout(function () {
        placements.init();
    }, 0);
});

require('org/thirdParty/bootstrap');
require('base/components/spinner');
require('svg4everybody');
require('slick-carousel');
require('picturefill');
