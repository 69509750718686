'use strict';

var device = require('org/utils/device');

/**
 * Set background image depending on current breakpoint
 * @returns {void}
 */
function setResponsiveBackground() {
    var isDesktop = device.desktop();

    $('.js-responsive-background').each(function () {
        var $block = $(this);

        $block.css('background-image', `url(${$block.data(isDesktop ? 'bg-dektop' : 'bg-mobile')})`);
    });
}

module.exports = function () {
    setResponsiveBackground();

    $(window).on('orientationchange resize', function () {
        setResponsiveBackground();
    });
};
