'use strict';

var focusHelper = require('base/components/focus');

module.exports = function () {
    var $navigationToggler = $('.account-navigation-toggler');
    var $navigation = $('.account-navigation');
    var $closeBtn = $('.close-account-navigation');

    $navigationToggler.on('click', function () {
        $navigation.addClass('in');
        $closeBtn.attr('aria-hidden', 'false').focus();
    });

    $closeBtn.on('click', function () {
        $navigation.removeClass('in');
        $(this).attr('aria-hidden', 'true');
        $navigationToggler.focus();
    });

    $('body').on('keydown', '#account-navigation', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#account-navigation',
            firstElementSelector: '.close-account-navigation',
            lastElementSelector: '.account-nav-link:last',
            nextToLastElementSelector: '.account-nav-link'
        };

        focusHelper.setTabNextFocus(focusParams);
    });
};
