/* eslint-disable no-param-reassign */
'use strict';
/* global pageContext */

var dataLayer = window.dataLayer || [];
var originalDataLayerPush = dataLayer.push.bind(dataLayer);

dataLayer.push = function (obj) {
    originalDataLayerPush(obj);
    $('body').trigger('dataLayer.push', obj);
};

/**
 * @param {Object} productObject The product data
 * @param {string} categoryName The category Name
 * @description gets the data for a product click
 */
function productClick(productObject) {
    var obj = {
        event: 'select_item',
        ecommerce_ga4: {
            currency: 'USD',
            items: [productObject]
        }
    };
    dataLayer.push({ ecommerce_ga4: null });
    dataLayer.push(obj);
}

/**
 * @param {Object} productObject The product data
 * @description gets the data for a wishlist click
 */
function wishlistClick(productObject) {
    var obj = {
        event: 'add_to_wishlist',
        ecommerce_ga4: {
            currency: 'USD',
            items: [productObject]
        }
    };
    dataLayer.push({ ecommerce_ga4: null });
    dataLayer.push(obj);
}

/**
 * @param {Object} productObject The product data
 * @description gets the data for product compare click
 */
function compareClick(productObject) {
    var obj = {
        event: 'compareClick',
        ecommerce: {
            click: {
                actionField: {
                    list: pageContext.type
                },
                products: []
            }
        }
    };

    obj.ecommerce.click.products.push(productObject);
    dataLayer.push({ ecommerce_ga4: null });
    dataLayer.push(obj);
}

/**
 * @description Click event for add product to cart
 * @param {Array} products Array of products
 * @param {string} event event
 */
function addToCart(products, event) {
    var quantityObj = {
        quantity: $('.quantity-select').val() || 1
    };
    var productsArr = products.map(function (product) {
        return $.extend(product, quantityObj);
    });
    var obj = {
        event: event,
        ecommerce: {
            currencyCode: 'USD',
            add: {
                products: productsArr
            }
        },
        cart: {
            add: {
                product: productsArr
            }
        }
    };
    dataLayer.push({ ecommerce_ga4: null });
    dataLayer.push(obj);
}

/**
 * @description Click event for add product to cart
 * @param {Array} products Array of products
 * @param {string} event event
 * @param {number} totalAddedPrice - total price of added products
 */
function addToCartGA(products, event, totalAddedPrice) {
    var quantityObj = {
        quantity: $('.quantity-select').val() || 1
    };
    var ga4Items = products.map(function (product) {
        return $.extend(product, quantityObj);
    });
    var obj = {
        event: event,
        ecommerce_ga4: {
            currency: 'USD',
            value: totalAddedPrice,
            items: ga4Items
        }
    };
    dataLayer.push({ ecommerce_ga4: null });
    dataLayer.push(obj);
}

/**
 * @description Click event for begin checkout
 * @param {Object} basket The product data
 */
function beginCheckoutGA(basket) {
    dataLayer.push({ ecommerce_ga4: null });
    dataLayer.push(basket);
}

/**
 * @description Edit shipping details event
 * @param {string} shippingElement the shipping element changed
 * @param {string} previousValue previous value for shippingElement
 * @param {string} shippingTier current shipping tier
 */
function editShippingGA(shippingElement, previousValue, shippingTier) {
    var obj = {
        event: 'change_shipping_info',
        ecommerce_ga4: {
            checkout_step_element: shippingElement || undefined,
            checkout_step_prior_value: previousValue || undefined,
            shipping_tier: shippingTier || undefined
        }
    };
    dataLayer.push({ ecommerce_ga4: null });
    dataLayer.push(obj);
}

/**
 * @description Convenience method for creating a click event.
 * @param {string} event event
 * @param {string} eventCategory event categroy
 * @param {string} eventAction event action
 * @param {string} eventLabel event layer
 */
function pushEvent(event, eventCategory, eventAction, eventLabel) {
    dataLayer.push({ ecommerce_ga4: null });
    dataLayer.push({
        event: event,
        eventCategory: eventCategory,
        eventAction: eventAction,
        eventLabel: eventLabel
    });
}

/**
 * Checks if an element is in the users viewport
 * @param {HTMLElement} element - Element to be checked
 * @return {boolean} returns true/false if element is in users viewport
 */
function isOnScreen(element) {
    var curPosTop = element.getBoundingClientRect().top;
    var curPosBot = element.getBoundingClientRect().bottom;
    var screenHeight = $(window).height();
    return ((curPosTop < screenHeight) && (curPosBot > 0));
}

var events = {
    account: function () {},
    checkout: function () {
        var gtmDataCheckoutMain = $('#checkout-main').attr('data-gtmdata');
        if (gtmDataCheckoutMain && gtmDataCheckoutMain !== 'null') {
            dataLayer.push({ ecommerce_ga4: null });
            dataLayer.push($.parseJSON(gtmDataCheckoutMain));
        }
        var gtmDataCheckoutMainGA = $('#checkout-main').attr('data-gtmdataga');
        if (gtmDataCheckoutMainGA && gtmDataCheckoutMainGA !== 'null') {
            dataLayer.push({ ecommerce_ga4: null });
            dataLayer.push($.parseJSON(gtmDataCheckoutMainGA));
        }
        $('body').on('gtm:shippingEngaged', function (e, data) {
            if (data && data.gtmCheckout) {
                var gtmDataCheckout = data.gtmCheckout;
                dataLayer.push({ ecommerce_ga4: null });
                dataLayer.push(gtmDataCheckout);
            }
            if (data && data.gtmCheckoutData) {
                var gtmDataInit = data.gtmCheckoutData;
                dataLayer.push({ ecommerce_ga4: null });
                dataLayer.push(gtmDataInit);
            }
            if (data && data.gtmCheckoutGA) {
                var gtmDataCheckoutGA = data.gtmCheckoutGA;
                dataLayer.push({ ecommerce_ga4: null });
                dataLayer.push(gtmDataCheckoutGA);
            }
        });
        var shippingAddressChange = false;
        $('body').on('gtm:shipping', function (e, data) {
            if (data && data.gtmCheckout && data.gtmCheckout.shippingSubmit) {
                var gtmDataShippingSubmit = data.gtmCheckout.shippingSubmit;
                dataLayer.push({ ecommerce_ga4: null });
                dataLayer.push(gtmDataShippingSubmit);
            }
            if (data && data.gtmCheckout && data.gtmCheckout.paymentLanding) {
                var gtmDataPaymentLanding = data.gtmCheckout.paymentLanding;
                dataLayer.push({ ecommerce_ga4: null });
                dataLayer.push(gtmDataPaymentLanding);
            }
            if (data && data.gtmCheckout && data.gtmCheckout.shippingSubmitGA) {
                var gtmDataShippingSubmitGA = data.gtmCheckout.shippingSubmitGA;
                dataLayer.push({ ecommerce_ga4: null });
                dataLayer.push(gtmDataShippingSubmitGA);
            }
        });

        $('body').on('gtm:billing', function (e, data) {
            $('body').trigger('afterpay:submit', data);
            if (data && data.gtmCheckout && data.gtmCheckout.paymentSubmit) {
                var gtmDataPaymentSubmit = data.gtmCheckout.paymentSubmit;
                dataLayer.push({ ecommerce_ga4: null });
                dataLayer.push(gtmDataPaymentSubmit);
                $('.place-order').attr('data-gtmdata', JSON.stringify(gtmDataPaymentSubmit));
            }
            if (data && data.gtmCheckout && data.gtmCheckout.summaryLanding) {
                var gtmDataSummaryLanding = data.gtmCheckout.summaryLanding;
                dataLayer.push({ ecommerce_ga4: null });
                dataLayer.push(gtmDataSummaryLanding);
            }
            if (data && data.gtmCheckout && data.gtmCheckout.orderReview) {
                var gtmDataOrderReview = data.gtmCheckout.orderReview;
                dataLayer.push(gtmDataOrderReview);
            }
        });

        $('body').on('gtm:placeOrder', function () {
            var gtmDataStringPlaceOrder = $('.place-order').attr('data-gtmdata');
            if (gtmDataStringPlaceOrder) {
                var gtmDataPlaceOrder = $.parseJSON(gtmDataStringPlaceOrder);

                if (gtmDataPlaceOrder) {
                    dataLayer.push({ ecommerce_ga4: null });
                    dataLayer.push(gtmDataPlaceOrder);
                }
            }
        });

        var shippingMethod = '';
        var edittedShippingMethod = '';

        $('.shipping-summary .edit-button').on('click', function () {
            shippingAddressChange = true;
            shippingMethod = $('#shipping-method-title').text();
        });
        $('button.submit-shipping').on('click', function () {
            if (shippingAddressChange === true) {
                shippingAddressChange = false;
                edittedShippingMethod = $('#shipping-method-title').text();
                if (shippingMethod !== $('#shipping-method-title').text()) {
                    editShippingGA('Shipping Method', shippingMethod, edittedShippingMethod);
                }
            }
        });
    },
    compare: function () {
        $('body').on('click', '.js-add-to-wishlist', function () {
            wishlistClick($.parseJSON($(this).attr('data-gtmdata')));
        });
    },
    confirmation: function () {
        var gtmData = $.parseJSON($('.container.receipt').attr('data-gtmdata'));
        dataLayer.push({ ecommerce_ga4: null });
        dataLayer.push(gtmData);
    },
    product: function () {
        $('body').on('product:afterAddToCart', function (event, data) {
            try {
                var addedProducts = data.addedProducts;
                var addedProductsGA = data.addedProductsGA;

                addToCart(addedProducts, 'addToCart');
                addToCartGA(addedProductsGA, 'add_to_cart', data.totalAddedPrice);
            } catch (e) {
                window.console.log(e);
            }
        });
        $('body').on('click', '.js-add-to-wishlist', function () {
            wishlistClick($.parseJSON($(this).attr('data-gtmdata')));
        });
    },
    search: function () {
        $('.product-link-ga').on('click', function (event) {
            var eventList = event.target.classList;
            var parentList = event.target.parentElement.classList;
            var nestedParentList = event.target.parentElement.parentElement.classList;

            if (!(eventList.contains('wishlistTile') ||
                parentList.contains('wishlistTile') ||
                nestedParentList.contains('wishlistTile'))) {
                productClick($.parseJSON($(this).attr('data-gtmdataga')), $('.category-name-gtm').val());
            }
        });
        $('body').on('click', '.js-add-to-wishlist', function () {
            wishlistClick($.parseJSON($(this).attr('data-gtmdata')));
        });
        $('.compare input[type=checkbox]').on('click', function () {
            var checked = $(this).is(':checked');
            if (checked) {
                compareClick($.parseJSON($(this).attr('data-gtmdata')));
            }
        });
    },
    wishlist: function () {},
    // events that should happen on every page
    filterEvents: {
        openFilter: function () {
            dataLayer.push({ ecommerce_ga4: null });
            window.dataLayer.push({
                event: 'filter_open'
            });
        },
        closeFilter: function () {
            dataLayer.push({ ecommerce_ga4: null });
            window.dataLayer.push({
                event: 'filter_close'
            });
        },
        interaction: function ($element) {
            var gtmData = {
                event: 'filter_interaction'
            };

            if ($element.is('.js-clear-filter')) {
                gtmData.filter_action = 'clear selections';
            } else if ($element.attr('aria-checked') === 'true' || $element.attr('data-is-selected') === 'true') {
                gtmData.filter_action = 'deselect';
            } else {
                gtmData.filter_action = 'select';
            }

            gtmData.filter_category = $element.data('gtm-filter-category');
            gtmData.filter_value = $element.data('gtm-filter-value');
            dataLayer.push({ ecommerce_ga4: null });
            window.dataLayer.push(gtmData);

            var gtmimpdata = window.gtmimpglobal;

            var gtmobj = {
                event: 'view_item_list',
                ecommerce_ga4: {
                    currency: 'USD'
                }
            };
            var filterAction = gtmData.filter_action;

            gtmobj.ecommerce_ga4.filter_category = (gtmimpdata) ? gtmimpdata.ecommerce_ga4.filter_category : '';
            gtmobj.ecommerce_ga4.filter_value = (gtmimpdata) ? gtmimpdata.ecommerce_ga4.filter_value : '';

            var addCategory = $element.data('gtm-filter-category');
            var addValue = $element.data('gtm-filter-value');

            if (window.gtmSelectedFilter_cats !== '') {
                window.gtmSelctedFilter_cats = (filterAction === 'select') ? window.gtmSelectedFilter_cats += (',' + addCategory) : window.gtmSelectedFilter_cats = window.gtmSelectedFilter_cats.replace(addCategory, '');
            } else {
                window.gtmSelectedFilter_cats = addCategory;
            }
            if (window.gtmSelectedFilter_vals !== '') {
                window.gtmSelctedFilter_vals = (filterAction === 'select') ? window.gtmSelectedFilter_vals += (',' + addValue) : window.gtmSelectedFilter_vals = window.gtmSelectedFilter_vals.replace(addValue, '');
            } else {
                window.gtmSelectedFilter_vals = addValue;
            }

            if (window.gtmSelectedFilter_cats[0] === ',') {
                window.gtmSelectedFilter_cats = window.gtmSelectedFilter_cats.substring(1);
            }
            if (window.gtmSelectedFilter_vals[0] === ',') {
                window.gtmSelectedFilter_vals = window.gtmSelectedFilter_vals.substring(1);
            }
            if (window.gtmSelectedFilter_cats.slice(-1) === ',') {
                window.gtmSelectedFilter_cats = window.gtmSelectedFilter_cats.slice(0, -1);
            }
            if (window.gtmSelectedFilter_vals.slice(-1) === ',') {
                window.gtmSelectedFilter_vals = window.gtmSelectedFilter_vals.slice(0, -1);
            }

            window.gtmSelectedFilter_cats = window.gtmSelectedFilter_cats.replace(',,', ',');
            window.gtmSelectedFilter_vals = window.gtmSelectedFilter_vals.replace(',,', ',');

            gtmobj.ecommerce_ga4.filter_category = window.gtmSelectedFilter_cats;
            gtmobj.ecommerce_ga4.filter_value = window.gtmSelectedFilter_vals;


            var gtmTmpObj = $('.js-impgtmdata-container').text();
            var gtmTmpObjJson = gtmTmpObj ? JSON.parse(gtmTmpObj.trim()) : null;
            if (gtmTmpObjJson) {
                gtmobj.ecommerce_ga4.items = gtmTmpObjJson.ecommerce_ga4.items;
            }
        }
    },
    all: function () {
        $('body').on('pushviewlist', function (e, elem) {
            var $element = $(elem);
            var gtmimpdata = window.gtmimpglobal;
            var gtmobj = {
                event: 'view_item_list',
                ecommerce_ga4: {
                    currency: 'USD'
                }
            };
            var filterAction;
            if ($element.is('.js-clear-filter')) {
                filterAction = 'clear selections';
            } else if ($element.attr('aria-checked') === 'true' || $element.attr('data-is-selected') === 'true') {
                filterAction = 'deselect';
            } else {
                filterAction = 'select';
            }

            gtmobj.ecommerce_ga4.filter_category = gtmimpdata.ecommerce_ga4.filter_category;
            gtmobj.ecommerce_ga4.filter_value = gtmimpdata.ecommerce_ga4.filter_value;

            var addCategory = $element.data('gtm-filter-category');
            var addValue = $element.data('gtm-filter-value');

            if (window.gtmSelectedFilter_cats !== '') {
                window.gtmSelctedFilter_cats = (filterAction === 'select') ? window.gtmSelectedFilter_cats : window.gtmSelectedFilter_cats = window.gtmSelectedFilter_cats.replace(addCategory, '');
            } else {
                window.gtmSelectedFilter_cats = addCategory;
            }
            if (window.gtmSelectedFilter_vals !== '') {
                window.gtmSelctedFilter_vals = (filterAction === 'select') ? window.gtmSelectedFilter_vals : window.gtmSelectedFilter_vals = window.gtmSelectedFilter_vals.replace(addValue, '');
            } else {
                window.gtmSelectedFilter_vals = addValue;
            }

            if (window.gtmSelectedFilter_cats[0] === ',') {
                window.gtmSelectedFilter_cats = window.gtmSelectedFilter_cats.substring(1);
            }
            if (window.gtmSelectedFilter_vals[0] === ',') {
                window.gtmSelectedFilter_vals = window.gtmSelectedFilter_vals.substring(1);
            }
            if (window.gtmSelectedFilter_cats.slice(-1) === ',') {
                window.gtmSelectedFilter_cats = window.gtmSelectedFilter_cats.slice(0, -1);
            }
            if (window.gtmSelectedFilter_vals.slice(-1) === ',') {
                window.gtmSelectedFilter_vals = window.gtmSelectedFilter_vals.slice(0, -1);
            }

            window.gtmSelectedFilter_cats = window.gtmSelectedFilter_cats.replace(',,', ',');
            window.gtmSelectedFilter_vals = window.gtmSelectedFilter_vals.replace(',,', ',');

            gtmobj.ecommerce_ga4.filter_category = window.gtmSelectedFilter_cats;
            gtmobj.ecommerce_ga4.filter_value = window.gtmSelectedFilter_vals;


            var gtmTmpObj = $('.js-impgtmdata-container').text();
            var gtmTmpObjJson = gtmTmpObj ? JSON.parse(gtmTmpObj.trim()) : null;
            if (gtmTmpObjJson) {
                gtmobj.ecommerce_ga4.items = gtmTmpObjJson.ecommerce_ga4.items;
            }

            dataLayer.push({ ecommerce_ga4: null });
            window.dataLayer.push(gtmobj);
        });
        $(document).on('click', '.checkout-begin', function () {
            beginCheckoutGA($.parseJSON($(this).attr('data-gtmdataga')));
        });
        $('.pdp-link .link').on('click', function () {
            productClick($.parseJSON($(this).attr('data-gtmdata')));
        });
        $('.nav-link.dropdown-toggle').on('click', function () {
            pushEvent('trackEvent', 'User Action', 'Header Click', $(this).html());
        });
        $('.navbar-header.brand').on('click', function () {
            pushEvent('trackEvent', 'User Action', 'Header Click', 'Home Link');
        });
        $('body').on('click', '.js-add-to-wishlist', function () {
            wishlistClick($.parseJSON($(this).attr('data-gtmdata')));
        });
        $('.btn-storelocator-search').on('click', function () {
            var storeSearchValue = $('input[name="postalCode"]').val();
            if (storeSearchValue) {
                var gtmData = $.parseJSON($(this).attr('data-gtmdata'));
                gtmData.search_type = storeSearchValue;
                dataLayer.push({ ecommerce_ga4: null });
                dataLayer.push(gtmData);
            }
        });
        $('.detect-location').on('click', function () {
            var gtmData = {
                event: 'search_store',
                search_type: 'use location'
            };
            dataLayer.push({ ecommerce_ga4: null });
            dataLayer.push(gtmData);
        });
        $('.make-my-store').on('click', function () {
            var storeId = $(this).attr('data-id');
            if (storeId) {
                var gtmData = $.parseJSON($(this).attr('data-gtmdata'));
                gtmData.activeStore = storeId;
                dataLayer.push({ ecommerce_ga4: null });
                dataLayer.push(gtmData);
            }
        });
        $('body').on('minicart:shown', function () {
            var gtmData = $.parseJSON($('.minicart-total').attr('data-gtmdata'));
            dataLayer.push({ ecommerce_ga4: null });
            dataLayer.push(gtmData);
        });
        $('.menu-dropdown-link').on('click', function () {
            var $categoryName = $(this).find('.category-name');
            var $navSubCategory = $(this).closest('li.menu-item').find('.menu-link-category-name').first();
            var $navCategory = $(this).closest('li.menu-item').closest('ul.menu-list').closest('li')
                .find('.menu-link-category-name')
                .first();
            if ($categoryName && $categoryName.text()) {
                dataLayer.push({ ecommerce_ga4: null });
                dataLayer.push({
                    event: 'headerNavigation',
                    navCategory: $navCategory.text(),
                    navSubCategory: $navSubCategory.text(),
                    navValue: $categoryName.text()
                });
            }
        });
        $('body').on('click', '.menu-item .menu-link', function () {
            var $categoryName = $(this).find('.menu-link-category-name');
            var $navSubCategory = $(this).find('.menu-link-category-name');
            var $navCategory = $(this).find('.menu-link-category-name');
            var $navCategory2 = $(this).closest('li.menu-item').parent().closest('li.menu-item')
            .find('.menu-link-category-name')
            .first();
            if ($categoryName && $categoryName.text()) {
                if ($categoryName.text().includes(' ')) {
                    $navCategory = $navCategory2;
                }
                dataLayer.push({ ecommerce_ga4: null });
                dataLayer.push({
                    event: 'headerNavigation',
                    navCategory: $navCategory.text(),
                    navSubCategory: $navSubCategory.text(),
                    navValue: $categoryName.text()
                });
            }
        });
        $('body').on('click', '.shop-all-button', function () {
            var $categoryName = $(this).find('.menu-link');
            var $navSubCategory = $(this).find('.menu-link');
            var $navCategory = $(this).find('.menu-link');
            if ($categoryName && $categoryName.text()) {
                dataLayer.push({ ecommerce_ga4: null });
                dataLayer.push({
                    event: 'headerNavigation',
                    navCategory: $navCategory.text(),
                    navSubCategory: $navSubCategory.text(),
                    navValue: $categoryName.text()
                });
            }
        });
        $('form[role="search"]').on('click', 'button', function () {
            dataLayer.push({ ecommerce_ga4: null });
            dataLayer.push({
                event: 'search_open',
                searchType: 'text'
            });
        });
        $('body').on('click', 'div.suggestions a.product-link, div.suggestions a.content-link', function () {
            var $searchField = $(this).closest('form').find('input[name="q"]');
            dataLayer.push({ ecommerce_ga4: null });
            dataLayer.push({
                event: 'searchSelect',
                searchType: 'text',
                searchResultsType: 'auto_search',
                searchAutoResult: $searchField.val(),
                searchSelectCategory: ($(this).hasClass('product-link') ? 'products' : 'content'),
                searchSelectItem: $(this).attr('data-id')
            });
        });
        $('.promotional-drawer__button').on('click', function () {
            dataLayer.push({ ecommerce_ga4: null });
            dataLayer.push({
                event: 'offers_open'
            });
        });
        $('body').on('click', 'div.promotional-drawer__carousel button.slick-next, div.promotional-drawer__carousel button.slick-prev', function () {
            dataLayer.push({ ecommerce_ga4: null });
            dataLayer.push({
                event: 'offers_view_more'
            });
        });
        $('body').on('click', 'div.promotional-drawer__carousel__slide', function () {
            var index = $(this).closest('.slick-slide').attr('data-slick-index');
            dataLayer.push({ ecommerce_ga4: null });
            dataLayer.push({
                event: 'offers_click',
                offerDrawerSpot: index,
                offerDrawerOffer: ''
            });
        });
        $('body').on('dataLayer.push', function (e, payload) {
            if (payload && payload.event === 'Attentive_Email') {
                var $setMCEmailURL = $('.js-set-mc-email-url');
                var $csrfToken = $('input[name="csrf_token"]');

                if ($setMCEmailURL.length === 0 || $csrfToken.length === 0) {
                    return;
                }

                var url = $setMCEmailURL.val();

                $.ajax({
                    url: url,
                    type: 'post',
                    dataType: 'json',
                    data: {
                        email: payload.email,
                        csrf_token: $csrfToken.val()
                    }
                });
            }
        });

        $('body').on('click', '.js-add-to-cart-product-tile', function () {
            try {
                addToCart($.parseJSON($(this).attr('data-gtmdata')), $('.quantity-select').val(), 'addToCart');
                addToCartGA($.parseJSON($(this).attr('data-gtmdataga')), $('.quantity-select').val(), 'add_to_cart');
            } catch (e) {
                window.console.log(e);
            }
        });

        var recentPushed = false;
        var similarPushed = false;
        var carouselPushed = false;
        var recentPushedContainer = document.getElementById('recent-products');
        var similarPushedContainer = document.getElementById('recommended-products');
        var carouselPushedContainer = document.getElementById('carousel-impressions');

        document.addEventListener('scroll', function () {
            if (!recentPushed) {
                if (recentPushedContainer && isOnScreen(recentPushedContainer)) {
                    var recommendations = document.getElementById('recent-products-ga4');
                    if (recommendations) {
                        recommendations = JSON.parse(recommendations.innerHTML);
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({ ecommerce_ga4: null });
                        window.dataLayer.push(recommendations);
                        recentPushed = true;
                    }
                }
            }
            if (!similarPushed) {
                if (similarPushedContainer && isOnScreen(similarPushedContainer)) {
                    var similarProducts = document.getElementById('recommended-products-ga4');
                    if (similarProducts) {
                        similarProducts = JSON.parse(similarProducts.innerHTML);
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({ ecommerce_ga4: null });
                        window.dataLayer.push(similarProducts);
                        similarPushed = true;
                    }
                }
            }
            if (!carouselPushed) {
                if (carouselPushedContainer && isOnScreen(carouselPushedContainer)) {
                    var carouselProducts = document.getElementById('carousel-impressions-ga4');
                    if (carouselProducts) {
                        carouselProducts = JSON.parse(carouselProducts.innerHTML);
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({ ecommerce_ga4: null });
                        window.dataLayer.push(carouselProducts);
                        carouselPushed = true;
                    }
                }
            }
        });
    }
};

/**
 *
 * @description Initialize the tag manager functionality
 * @param {string} nameSpace The current name space
 */
exports.init = function (nameSpace) {
    var ns = nameSpace || pageContext.ns;
    if (ns && events[ns]) {
        events[ns]();
    }
    events.all();
};

exports.events = events;

