'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');

$(document).ready(function () {
    $('.nav-tabs .nav-item .nav-link').not('.active').attr('tabindex', '-1');

    $('.tab-pane.active').attr('tabindex', '0');

    $('.nav-tabs .nav-item .nav-link').on('shown.bs.tab', function (event) {
        $(event.target).removeAttr('tabindex');
        $(event.relatedTarget).attr('tabindex', '-1');
        $('.tab-pane.active').attr('tabindex', '0');
        $('.tab-pane:not(.active)').removeAttr('tabindex');
    });

    $('.attr-radiogroup').on('focusin', function () {
        const $radiogroup = $(this);
        if ($radiogroup.find('button[role="radio"][aria-checked="true"]').length > 0 && $radiogroup.find('button[role="radio"][aria-current="true"]').length === 0) {
            const $selectedElement = $radiogroup.find('button[role="radio"][aria-checked="true"]');
            $selectedElement.siblings('button[role="radio"]').attr('aria-current', 'false');
            $selectedElement.attr('aria-current', 'true').trigger('focus');
        }
    });


    $('.js-attr-tooltip').on('keydown', function (e) {
        if (e.key === ' ' || e.key === 'Enter') {
            $(this).trigger('click');
        }
    });

    keyboardAccessibility('.attr-radiogroup',
        {
            37: function ($current) { // left
                let $nextElement = null;
                if ($current.is(':first-of-type')) {
                    $nextElement = $current.siblings('button[role="radio"]').last();
                } else {
                    $nextElement = $current.prev();
                }
                $nextElement.siblings('button[role="radio"]').attr('aria-current', 'false');
                $nextElement.attr('aria-current', 'true').trigger('focus');
            },
            38: function ($current) { // up
                let $nextElement = null;
                if ($current.is(':first-of-type')) {
                    $nextElement = $current.siblings('button[role="radio"]').last();
                } else {
                    $nextElement = $current.prev();
                }
                $nextElement.siblings('button[role="radio"]').attr('aria-current', 'false');
                $nextElement.attr('aria-current', 'true').trigger('focus');
            },
            39: function ($current) { // right
                let $nextElement = null;
                if ($current.is(':last-of-type')) {
                    $nextElement = $current.siblings('button[role="radio"]').first();
                } else {
                    $nextElement = $current.next();
                }
                $nextElement.siblings('button[role="radio"]').attr('aria-current', 'false');
                $nextElement.attr('aria-current', 'true').trigger('focus');
            },
            40: function ($current) { // down
                let $nextElement = null;
                if ($current.is(':last-of-type')) {
                    $nextElement = $current.siblings('button[role="radio"]').first();
                } else {
                    $nextElement = $current.next();
                }
                $nextElement.siblings('button[role="radio"]').attr('aria-current', 'false');
                $nextElement.attr('aria-current', 'true').trigger('focus');
            }
        },
        function () {
            return $(':focus');
        }
    );

    keyboardAccessibility('.nav-tabs .nav-item .nav-link',
        {
            39: function (navLink) { // right
                const $navItem = navLink.closest('.nav-item');
                if ($navItem.is(':last-child')) {
                    $navItem.siblings().first().find('.nav-link').tab('show');
                } else {
                    $navItem.next().find('.nav-link').tab('show');
                }
            },
            37: function (navLink) { // left
                const $navItem = navLink.closest('.nav-item');
                if ($navItem.is(':first-child')) {
                    $navItem.siblings().last().find('.nav-link').tab('show');
                } else {
                    $navItem.prev().find('.nav-link').tab('show');
                }
            }
        },
        function () {
            return $(this).parent();
        }
    );
});
