'use strict';

var focusHelper = require('base/components/focus');

/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */
function showConsentModal() {
    if (!$('.tracking-consent').data('caonline')) {
        return;
    }

    var urlContent = $('.tracking-consent').data('url');
    var urlAccept = $('.tracking-consent').data('accept');
    var urlReject = $('.tracking-consent').data('reject');
    var moreInfo = $('.tracking-consent').data('moreinfo');
    var textYes = $('.tracking-consent').data('accepttext');
    var textNo = $('.tracking-consent').data('moreinfotext');

    var htmlString = '<!-- Modal -->'
        + '<div class="modal show" id="consent-tracking" style="display: block;">'
        + '<div class="modal-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content" role="region" aria-label="cookie consent banner">'
        + '<div class="modal-body-container">'
        + '<div class="modal-body tracking-body"></div>'
        + '<div class="modal-header d-sm-none">'
        + '    <button type="button" class="decline btn btn-link" data-url="' + urlReject + '" aria-label="Close">'
        + '        <span aria-hidden="true"></span>'
        + '    </button>'
        + '</div>'
        + '</div>'
        + '<div class="modal-footer">'
        + '<div class="button-wrapper">'
        + '<a class="btn btn-link more-info" href="' + moreInfo + '" >'
        + textNo
        + '</a>'
        + '<button class="affirm btn btn-link" data-url="' + urlAccept + '" autofocus data-dismiss="modal">'
        + textYes
        + '</button>'
        + '<button type="button" class="decline btn btn-link" data-url="' + urlReject + '" aria-label="Close">'
        + '<span aria-hidden="true"></span>'
        + '</button>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('a.skip[href="#maincontent"').after(htmlString);

    $.ajax({
        url: urlContent,
        type: 'get',
        dataType: 'html',
        success: function (response) {
            $('.modal-body.tracking-body').html(response);
        }
    });

    $('#consent-tracking .button-wrapper button, #consent-tracking .decline').click(function (e) {
        e.preventDefault();
        $.spinner().stop();
        $('.modal-backdrop').remove();
        var url = $(this).data('url');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function () {
                $('#consent-tracking').remove();
            },
            error: function () {
                $('#consent-tracking').remove();
            }
        });
    });
}

module.exports = function () {
    if ($('.consented').length === 0 && $('.tracking-consent').hasClass('api-true')) {
        showConsentModal();
    }

    if ($('.tracking-consent').hasClass('api-true')) {
        $('.tracking-consent').click(function () {
            showConsentModal();
        });
    }

    $('body').on('shown.bs.modal', '#consent-tracking', function () {
        $('#consent-tracking').siblings().attr('aria-hidden', 'true');
        $('#consent-tracking .close').focus();
    });

    $('body').on('hidden.bs.modal', '#consent-tracking', function () {
        $('#consent-tracking').siblings().attr('aria-hidden', 'false');
    });

    $('body').on('keydown', '#consent-tracking', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#consent-tracking',
            firstElementSelector: '.affirm',
            lastElementSelector: '.decline',
            nextToLastElementSelector: '.affirm'
        };
        focusHelper.setTabNextFocus(focusParams);
    });
};
