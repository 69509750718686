const scrollAnimate = require('base/components/scrollAnimate');
var slickConfigs = require('org/config/slickConfigs');
var carouselHelper = require('org/utils/carouselHelper');
const initSlider = () => {
    const $slider = $('.js-promotional-drawer__carousel');
    if ($slider.length > 0) {
        carouselHelper.processCarouselAccessibility($slider);
        $slider.not('.slick-initialized').slick(slickConfigs.promotionalDrawerCarousel);
    }
};

/**
 * Process GTM events for the offers drawer
 */
function gtmEvents() {
    var $collapsePromo = $('.collapse-promo');

    /**
     * Push data to data layer
     * @param {Object} data - data to be pushed into data layer
     */
    function pushDataToLayer(data) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
    }

    $collapsePromo.on({
        'shown.bs.collapse': function () {
            pushDataToLayer({
                event: 'offers_open'
            });

            var offerDrawerItems = [];
            $('.js-promotional-drawer__carousel.slick-initialized .slick-slide').each(function () {
                var $this = $(this);
                var $drawerItem = $this.find('[data-analytics-intcmp]');

                offerDrawerItems.push({
                    promotion_id: $drawerItem.attr('promo-id') || '',
                    promotion_name: $drawerItem.attr('promo-name') || '',
                    creative_name: $drawerItem.attr('data-analytics-intcmp'),
                    creative_slot: 'offer drawer ' + (parseInt($this.attr('data-slick-index'), 10) + 1),
                    location_id: (window.pageContext && window.pageContext.ns) || 'offer drawer'
                });
            });

            pushDataToLayer({
                event: 'view_promotion',
                ecommerce: {
                    items: offerDrawerItems
                }
            });
        },
        'hidden.bs.collapse': function () {
            pushDataToLayer({
                event: 'offers_closed'
            });
        }
    });

    $('body').on('beforeChange', '.js-promotional-drawer__carousel.slick-initialized', function (e, slick, currentSlide, nextSlide) {
        if (currentSlide !== nextSlide) {
            pushDataToLayer({
                event: 'scroll_offer_drawer'
            });
        }
    });

    $('body').on('click', '.js-promotional-drawer__carousel.slick-initialized [data-analytics-intcmp]', function (e) {
        e.preventDefault();

        var $this = $(this);

        pushDataToLayer({
            event: 'select_promotion',
            ecommerce: {
                items: [{
                    promotion_id: $this.attr('promo-id') || '',
                    promotion_name: $this.attr('promo-name') || '',
                    creative_name: $this.attr('data-analytics-intcmp'),
                    creative_slot: 'offer drawer ' + (parseInt($this.closest('.slick-slide').attr('data-slick-index'), 10) + 1),
                    location_id: (window.pageContext && window.pageContext.ns) || 'offer drawer'
                }]
            }
        });

        window.location.href = $this.attr('href');
    });
}

module.exports = {
    initScrollTop: () => {
        const $link = $('.js-scroll-top');
        const $anchor = $('#maincontent');
        const $firstBodyLink = $('body').find('a, [role="button"], button').not('.skip').first();

        $link.on('click', (e) => {
            e.preventDefault();
            scrollAnimate($anchor);
            $firstBodyLink.trigger('focus');
        });
    },
    initModalToggle: () => {
        const $container = $('.js-promotional-drawer');
        const $button = $('.js-promotional-drawer__toggle-modal');

        $button.on('click', () => {
            $container.toggleClass('promotional-drawer--active');
        });
    },

    initSlider: initSlider,

    refreshSlider: () => {
        $('#collapsePromo').on('show.bs.collapse', () => {
            $('.js-promotional-drawer__carousel.slick-initialized').slick('refresh');
        });
    },
    gtmEvents: gtmEvents
};
