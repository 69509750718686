'use strict';

var lcpCart = {
    init: function () {
        if ($('.cart-page').length > 0) {
            lcpCart.handlePliUpdate();
        }
    },

    handlePliUpdate: function () {
        $('body').on('change', 'input[name="protection"]', function () {
            var input = $(this);
            var container = input.parents('.protection').first();
            var lcpItem = container.find('input[name="lcpItemID"]').val();
            var data = {
                uuid: container.data('lcp-uuid'),
                add: input.is(':checked'),
                lcpItem: lcpItem
            };
            container.spinner().start();
            $.getJSON(container.data('update-pli'), data, function (resp) {
                $('body').trigger('cartUpdated', resp);
                container.spinner().stop();
            });
        });
    }
};

module.exports = lcpCart;
