'use strict';
/**
 * Default Zoom config
 */

module.exports = {
    url: false,
    on: 'mouseover',
    duration: 120,
    target: false,
    touch: true,
    magnify: 1,
    callback: false,
    onZoomIn: false,
    onZoomOut: false
};
