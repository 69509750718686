'use strict';

module.exports = function () {
    var $accountPage = $('.account-page');
    var isAccountPage = $accountPage.length > 0;

    if (!isAccountPage) {
        sessionStorage.removeItem('accountItemsQty');
        return;
    }

    var url = $accountPage.data('get-items-qty-url');
    var $accountSidebar = $('.account-sidebar');
    var $jwBoxQty = $accountSidebar.find('.jw-box-qty');
    var $wishlistQty = $accountSidebar.find('.wishlist-qty');
    var $ordersQty = $accountSidebar.find('.orders-qty');
    var accountItemsQty = sessionStorage.getItem('accountItemsQty');
    var accountItemsQtyJSON = accountItemsQty ? JSON.parse(accountItemsQty) : null;
    var currentDate = new Date();
    var sessionCreationDate = accountItemsQtyJSON ? new Date(accountItemsQtyJSON.creationTime) : null;
    var timeDiff = sessionCreationDate ? (currentDate - sessionCreationDate) / (1000 * 60) : null;

    /**
     * Fills quantity items in account sidebar
     * @param {Object} data - Data with items quantity
     */
    function fillQtyItems(data) {
        $jwBoxQty.text(`(${data.totaljwBoxProductsQty})`);
        $wishlistQty.text(`(${data.totalWishlistItems})`);
        $ordersQty.text(`(${data.totalOrdersQty})`);
    }

    if (!timeDiff || timeDiff >= 5) {
        sessionStorage.removeItem('accountItemsQty');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                fillQtyItems(data);

                sessionStorage.setItem('accountItemsQty', JSON.stringify({
                    totaljwBoxProductsQty: data.totaljwBoxProductsQty,
                    totalOrdersQty: data.totalOrdersQty,
                    totalWishlistItems: data.totalWishlistItems,
                    creationTime: currentDate.toISOString()
                }));
            }
        });
    } else if (accountItemsQtyJSON) {
        fillQtyItems(accountItemsQtyJSON);
    }
};
