/* eslint-disable */
'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');

function setAttribute ($element) {
    let $selectContainer = $element.closest('.custom-select-wrapper');
    let selectedId;
    if (!$element.hasClass('selected')) {
        $selectContainer.find('.custom-select-list li').removeClass('selected').attr('aria-selected', 'false');
        $element.addClass('selected').attr('aria-selected', 'true');
        selectedId = $element.attr('id');
        $selectContainer.find('.btn-select').html($element.html())
        .attr('value', $element.data('value'))
        .trigger('change');
    }
    $selectContainer.find('.btn-select').attr('aria-activedescendant', selectedId);
    setTimeout(() => { closeDropdown(); }, 250);
};

function closeDropdown () {
    $('.btn-select[aria-expanded="true"]').attr('aria-expanded', 'false');
    $('.custom-select-list:not(.d-none)').addClass('d-none');
}

// select a product attribute
$('body').on('click', '.custom-select-list li', function (e) {
    var $that = $(this);
    setAttribute($that);
});

// change list display on click
$('body').on('click', function (e) {
    var $target = $(e.target);
    if ($target.is('.btn-select') || $target.is('.btn-select span')) {
        var open;
        $target.closest('.custom-select-wrapper').find('.btn-select').attr('aria-expanded', 'true');
        var $listContainer = $target.closest('.custom-select-wrapper').find('.custom-select-list');
        if ($listContainer.hasClass('d-none')) {
            open = true;
        }
        $('.custom-select-list:not(".d-none")').addClass('d-none');
        if (open) {
            $listContainer.removeClass('d-none');
        }
    }

    var $customSelectContainer = $target.closest('.custom-select-wrapper');
    if (!$target.hasClass('custom-select-wrapper') && (!$customSelectContainer || $customSelectContainer.length <= 0)) {
        $('.custom-select-list').addClass('d-none');
    }
});

$('body').on('focusout', '.btn-select', function (e) {
    var $that = $(this);

    $($that).attr('aria-expanded', 'false');
});

keyboardAccessibility('.custom-select-wrapper',
    {
        38: function ($element) { // up
            const $options = $element.find('.custom-select-item');
            let $prevOption = null;
            const $selectedOption = $element.find('.custom-select-item[aria-selected="true"]');
            if ($selectedOption.length > 0 && $selectedOption.prev().length > 0) { // is there a selected option and another option before this one
                $prevOption = $selectedOption.prev();
            } else { // if no selected option then go to last option
                $prevOption = $options.last();
            }
            $prevOption.attr('aria-selected', 'true');
            $prevOption.siblings().attr('aria-selected', 'false');
        },
        40: function ($element) { // down
            const $options = $element.find('.custom-select-item');
            let $nextOption = null;
            const $selectedOption = $element.find('.custom-select-item[aria-selected="true"]');
            if ($selectedOption.length > 0 && $selectedOption.next().length > 0) { // is there a selected option and another option after this one
                $nextOption = $selectedOption.next();
            } else { // if no selected option then go to first option
                $nextOption = $options.first();
            }
            $nextOption.attr('aria-selected', 'true');
            $nextOption.siblings().attr('aria-selected', 'false');
        },
        27: function ($element) { // escape
            if ($element.find('.btn-select').attr('aria-expanded') === 'true') { // only catch the ESC if the dropdown is open
                closeDropdown();
            }
        },
        13: function ($element) { // enter
            if ($element.find('.btn-select').attr('aria-expanded') === 'true') { // only catch the ENTER if the dropdown is open
                const $selectedOption = $element.find('.custom-select-item[aria-selected="true"]');
                setAttribute($selectedOption);
            }
        }
    },
    function () {
        return $(this);
    }
);
