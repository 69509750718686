'use strict';

/**
 * Scrolls the page to the HK unavailable error on minicart
 */
const scrollToHkUnavailableMinicart = () => {
    var $productSummary = $('.minicart .product-summary');
    var $hkUnavailable = $('.minicart .hk-unavailable-block:not(.d-none)').first();
    if ($hkUnavailable.length === 0) return;
    var productSummaryScrollTop = $productSummary.scrollTop();
    var $parent = $hkUnavailable.closest('.js-product-line-item');
    var position = productSummaryScrollTop + $parent.position().top;

    $productSummary.animate({
        scrollTop: position
    }, 500);
};

/**
 * Scrolls the page to the HK unavailable error on checkout
 */
const scrollToHkUnavailableCheckout = () => {
    var $hkUnavailable = $('.checkout-main .hk-unavailable-block').first();
    if ($hkUnavailable.length === 0) return;
    var position = $hkUnavailable.offset().top;
    $('html, body').animate({
        scrollTop: position - 20
    }, 500);
};

/**
 * Scrolls the page to the HK unavailable error on cart
 */
const scrollToHkUnavailableCart = () => {
    var $hkUnavailable = $('.cart-page .hk-unavailable-block').first();
    if ($hkUnavailable.length === 0) return;
    var position = $hkUnavailable.offset().top;
    // bypass auto scrolling.
    if ('scrollRestoration' in history) {
        history.scrollRestoration = 'manual';
    }
    $('html, body').animate({
        scrollTop: position - $('header').height() - 20
    }, 500);
};

/**
 * Scrolls the page to the HK unavailable error
 * @param {string} pageType – minicart, cart, checkout
 */
const scrollToHkUnavailable = (pageType) => {
    switch (pageType) {
        case 'minicart':
            scrollToHkUnavailableMinicart();
            break;
        case 'checkout':
            scrollToHkUnavailableCheckout();
            break;

        default: // cart
            scrollToHkUnavailableCart();
            break;
    }
};

/**
 * Collects the info regarding the amount of unique the HKUUIDs
 * @param {JQuery} $elements list of elements to get the data from
 * @returns {Array} array of resultant unique hkuuids
 */
const buildArrOfUniqueHKUUIDs = ($elements) => {
    let arr = [];

    // Collect the info regarding the amount of unique the HKUUIDs
    $elements.each(function () {
        const hkuuid = $(this).data('hkuuid');
        if (hkuuid && arr.indexOf(hkuuid) < 0) {
            arr.push(hkuuid);
        }
    });

    return arr;
};


/**
 * A check where the code is being invoked on the cart page
 * @returns {boolean} - whether it is the cart page
 */
const isCartPage = () => {
    return !!$('.cart-page').length;
};

/**
 * Builds a wrapper element based on the context.
 * @param {string} hkuuid - The HKUUID to be associated with the HK product
 * @returns {string} - The HTML string representing the wrapper element
 */
const buildWrapper = (hkuuid) => {
    if (isCartPage()) {
        return `<div class="js-list-elements-wrapper card product-info-wrapper" data-hkuuid="${hkuuid}"></div>`;
    }

    return `<div class="row col-12 product-line-item-line-wrapper js-list-elements-wrapper mx-0 px-0 mb-4" data-hkuuid="${hkuuid}" role="group" aria-label="product-wrapper"></div>`;
};

/**
 * Gets the selector based on the page context.
 * @returns {string} - The selector to be used in the DOM search
 */
const getSelector = () => {
    return isCartPage() ? 'product-info' : 'product-line-item-line';
};

/**
 * Gets the selector based on the page context.
 * @param {JQuery} $wrapperEl - Wrapper element jquery object
 * @param {string} hkuuid - UUID of the HK product set
 */
const insertItems = ($wrapperEl, hkuuid) => {
    const selectorClassname = getSelector();
    const $allRelatedItemsByHKUUID = $(`.${selectorClassname}[data-hkuuid="${hkuuid}"]`);

    $allRelatedItemsByHKUUID.each((index, el) => {
        const $productCard = $(el);

        if (!$wrapperEl[0].contains($productCard[0])) {
            var $nextEl = $productCard.next();

            if (index === 0) $wrapperEl.insertAfter($productCard);

            $productCard.appendTo($wrapperEl);
            if ($nextEl.is('.js-lcp-row-divider')) {
                $nextEl.appendTo($wrapperEl);
            }
        }
    });
};

var hkUtils = {
    groupHKitemsByUUID: function () {
        const selectorClassname = getSelector();
        const $elements = $(`.${selectorClassname}[data-hkuuid]`);
        let hkuuidsArr = buildArrOfUniqueHKUUIDs($elements);

        // Replace the original elements with the grouped elements
        hkuuidsArr.forEach((hkuuid) => {
            if (!$(`.${selectorClassname}[data-hkuuid="${hkuuid}"]`).length) return;
            let $wrapperEl;

            if ($(`.${selectorClassname}-wrapper[data-hkuuid="${hkuuid}"]`).length) {
                $wrapperEl = $('.js-list-elements-wrapper');
            } else {
                $wrapperEl = $(buildWrapper(hkuuid));
            }

            insertItems($wrapperEl, hkuuid);
        });
    },
    scrollToHkUnavailable: scrollToHkUnavailable
};

module.exports = hkUtils;
