'use strict';

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} $iconToHide - icon that was clicked to add a product to the wishlist
 * @param {Object} $iconToShow - icon that needs to be shown
 */
function displayMessageAndChangeIcon(data, $iconToHide, $iconToShow) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
        $iconToHide.addClass('d-none');
        $iconToShow.removeClass('d-none');
    } else {
        status = 'alert-danger';
    }

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append(
            '<div class="add-to-wishlist-messages "></div>'
        );
    }

    var $alertMsg = $('<div role="alert" class="add-to-wishlist-alert text-center alert ' + status + '">' + data.msg + '</div>')
        .appendTo($('.add-to-wishlist-messages'));

    setTimeout(function () {
        $alertMsg.fadeTo(500, 0).slideUp(500, function () {
            $(this).remove();
        });
    }, 4000);
}

/**
 * get wishlist data for element
 * @param {Object} $addToWishlistBtn - icon that was clicked
 * @param {boolean} isWishlistRemoveAction - true if it's remove action
 * @returns {Object} object with data
 */
function getWishlistData($addToWishlistBtn, isWishlistRemoveAction) {
    var dataObj = {};

    if ($addToWishlistBtn.length === 0) return dataObj;

    var $productDetail = $addToWishlistBtn.closest('.product-detail');
    var optionId = $productDetail.find('.product-option').attr('data-option-id');
    var optionVal = $productDetail.find('.options-select option:selected').attr('data-value-id');
    dataObj.url = $addToWishlistBtn.attr('href');

    if ($addToWishlistBtn.closest('.product').length) {
        dataObj.pid = $addToWishlistBtn.closest('.product').attr('data-pid');
    } else if ($addToWishlistBtn.closest('.discover-module-container').length) {
        dataObj.pid = JSON.parse($addToWishlistBtn.closest('.wishlistTile').attr('data-gtmdata')).item_id;
    }

    dataObj.optionId = optionId || null;
    dataObj.optionVal = optionVal || null;

    if ($addToWishlistBtn.closest('.js-lm-wishlist-carousel').length) {
        dataObj.iconToHide = $('#discoverModuleTiles').find('.js-remove-from-wishlist[data-pid="' + dataObj.pid + '"]');
        dataObj.iconToShow = isWishlistRemoveAction ? dataObj.iconToHide.siblings('.js-add-to-wishlist') : dataObj.iconToHide.siblings('.js-remove-from-wishlist');
    } else {
        dataObj.iconToHide = $addToWishlistBtn;
        dataObj.iconToShow = isWishlistRemoveAction ? $addToWishlistBtn.siblings('.js-add-to-wishlist') : $addToWishlistBtn.siblings('.js-remove-from-wishlist');
    }

    dataObj.isWishlistRemoveAction = isWishlistRemoveAction;

    if ($productDetail.data('hk-uuid')) {
        dataObj.hkUUID = $productDetail.data('hk-uuid');
    }

    return dataObj;
}

/**
 * perfrom ajax call for wishlist add/remove
 * @param {string} url - url link
 * @param {Object} data - data obj
 */
function performWishlistAjaxCall(url, data) {
    var ajaxObj = {
        url: url,
        type: data.isWishlistRemoveAction ? 'get' : 'post',
        dataType: 'json',

        success: function (responseData) {
            displayMessageAndChangeIcon(responseData, data.iconToHide, data.iconToShow);

            if (data.isWishlistRemoveAction) {
                $('body').trigger('removeWishlistItem', data.pid);
            } else {
                $('body').trigger('addWishlistItem', data.pid);
            }
        },
        error: function (err) {
            displayMessageAndChangeIcon(err, data.iconToHide, data.iconToShow);
        }
    };

    if (!data.isWishlistRemoveAction) {
        ajaxObj.data = {
            pid: data.pid,
            optionId: data.optionId,
            optionVal: data.optionVal,
            hkUUID: data.hkUUID
        };
    }

    $.spinner().start();
    $.ajax(ajaxObj);
}

module.exports = {
    addToWishlist: function () {
        $('body').on('click', '.js-add-to-wishlist', function (e) {
            e.preventDefault();
            var $this = $(this);
            var wishlistData = getWishlistData($this);

            if (!wishlistData.url || !wishlistData.pid) {
                return;
            }

            performWishlistAjaxCall(wishlistData.url, wishlistData);
        });
    },
    removeFromWishlist: function () {
        $('body').on('click', '.js-remove-from-wishlist', function (e) {
            e.preventDefault();
            var $this = $(this);
            var wishlistData = getWishlistData($this, true);
            var url;
            var paramsToAppend = {
                pid: wishlistData.pid
            };

            if (wishlistData.hkUUID) {
                paramsToAppend.hkUUID = wishlistData.hkUUID;
            }

            url = appendToUrl(wishlistData.url, paramsToAppend);

            if (wishlistData.optionId || wishlistData.optionVal) {
                url = appendToUrl(url, { optionId: wishlistData.optionId, optionVal: wishlistData.optionVal });
            }

            performWishlistAjaxCall(url, wishlistData);
        });
    }
};
