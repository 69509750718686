'use strict';

var place = {
    priv: {
        fetchPage: function () {
            var realPlacements = [];
            var placements = $('body').find('[id^="bread-payments-"]').map(function () {
                var self = $(this);
                var realPlace = place.priv.buildPlace(self);
                realPlacements.push(realPlace);
                return realPlace;
            }).get();
            window.console.log('found ' + placements.length + ' placements on this page');
            window.console.log(placements);

            return realPlacements;
        },

        buildPlace: function (elem) {
            var data = elem.data();
            var id = elem.attr('id');
            return {
                financingType: 'card',
                locationType: data.name,
                placementId: data.id,
                domID: id,
                order: {
                    totalPrice: parseInt((data.total * 100).toFixed(0), 10)
                }
            };
        }
    },

    init: function () {
        var placements = place.priv.fetchPage();
        var config = $('#bpconf');
        if (placements.length > 0 && config.length > 0 && window.BreadPayments) {
            var conf = config.data();
            window.BreadPayments.setup({
                loyaltyID: conf.loyaltyId,
                storeNumber: conf.storeNumber,
                existingCardHolder: conf.isCardHolder,
                env: conf.env
            });
            window.BreadPayments.registerPlacements(placements);
        }
    }
};

module.exports = place;
