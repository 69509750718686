module.exports = {
    initVimeoModal: () => {
        $(document).on('click', '.js-vimeo-modal', e => {
            const $button = $(e.currentTarget);
            const vimeoID = $button.data('vimeo');

            if (vimeoID) {
                const html = `
                    <div class="modal fade" id="vimeoModal" tabindex="-1" role="dialog" aria-labelledby="vimeoModal">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div class="modal-body">
                                <iframe class="iframe--vimeo" src="https://player.vimeo.com/video/${vimeoID}" frameborder="0" allowfullscreen />
                            </div>
                        </div>
                    </div>
                </div>`;

                $('body').append(html);

                const $modal = $('#vimeoModal');

                $modal.on('hidden.bs.modal', () => {
                    $modal.remove();
                });

                $modal.modal('show');
            }
        });
    }
};
