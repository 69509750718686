const processCarouselAccessibility = function ($carousel) {
    const setAttributes = function ($slider) {
        $carousel.find('.slick-slide').each(function () {
            const $this = $(this);

            $this.attr('aria-current', $this.hasClass('slick-active'));
            $this.attr('role', 'group');
            $this.attr('aria-label', `slide ${$this.data('slick-index') + 1} of ${$slider.slideCount}`);
        });
    };

    $carousel.on('init afterChange', function (e, $slider) {
        setAttributes($slider);
        const $slickDots = $slider.$dots && $($slider.$dots[0]);

        if ($slickDots) {
            $slickDots.find('li').each(function () {
                const $this = $(this);
                $this.attr('aria-current', $this.hasClass('slick-active'));
            });
        }
    });
};

module.exports = {
    processCarouselAccessibility: processCarouselAccessibility
};
