'use strict';

var bp = {
    init: function () {
        var snipVersion = {
            snippetVersion: '1.2.1'
        };
        // eslint-disable-next-line no-sequences, no-cond-assign
        if (window.BreadPayments = window.BreadPayments || snipVersion, !window.BreadPayments.init) {
            var b = window.BreadPayments;
            b.on = function () {
                var t = ['on'].concat(Array.prototype.slice.call(arguments));
                (b.q = b.q || []).push(t);
            };
            b.submitRtps = function () {
                var t = ['submitRtps'].concat(Array.prototype.slice.call(arguments));
                (b.q = b.q || []).push(t);
            };
            b.setup = function () {
                var t = ['setup'].concat(Array.prototype.slice.call(arguments));
                (b.q = b.q || []).push(t);
            };
            b.registerPlacements = function () {
                var t = ['registerPlacements'].concat(Array.prototype.slice.call(arguments));
                (b.q = b.q || []).push(t);
            };
        }
    },
    handleEvents: function () {
        window.BreadPayments.on('INSTALLMENT:APPLICATION_DECISIONED', function (installmentResult) {
            // Handle successful installment checkout
            window.console.log(installmentResult);
        });
        window.BreadPayments.on('CARD:RECEIVE_APPLICATION_RESULT', function (cardResult) {
            window.console.log(cardResult);
            var conf = $('#bpconf').data();
            // Handle Application Result
            switch (cardResult.result) {
                case 'APPROVED':
                    // Handle approved case
                    if (conf) {
                        var tID = cardResult.callId;
                        $.post(conf.lookupUrl, { tID: tID }, function (data) {
                            window.console.log(data);
                        }, 'json');
                    }
                    break;
                case 'PENDING':
            // Handle pending case
                    break;
                case 'ACCOUNT_EXISTS':
            // Handle account exists case
                    break;
                case 'DECLINED':
            // Handle declined case
                    break;
                default:
                case 'ERROR':
            // Handle error case
                    break;
            }
        });
    }
};

module.exports = bp;
