'use strict';

var hkUtils = require('../utils/hkutils');

/**
 * Show error message when HK products are not available
 */
function showUnavailabeMsgHK() {
    $('body').on('checkout:showUnavailabeMsgHK', function (e, hkUUIDs) {
        var $hkUnavailableBlock = $('.js-hk-unavailable-container .hk-unavailable-block');
        var uuids = hkUUIDs.split(',');
        var $hkBlock;
        var $hkUnavailableBlockClone;
        var $firstHKProduct;
        var pdpLink;
        uuids.forEach(function (uuid) {
            $hkBlock = $(`.js-list-elements-wrapper[data-hkuuid="${uuid}"]`);
            $firstHKProduct = $hkBlock.children().first();
            pdpLink = $firstHKProduct.data('pdp-link');
            $hkUnavailableBlockClone = $hkUnavailableBlock.clone();
            $hkUnavailableBlockClone.find('.js-hk-review-ring').attr('href', pdpLink);
            $hkUnavailableBlockClone.insertBefore($firstHKProduct);
        });
        hkUtils.scrollToHkUnavailable('checkout');
    });
}
/**
 * Handle HK availability on minicart hovering
 */
function minicart() {
    var $minicart = $('.minicart');
    var hkActionURL = $minicart.data('hk-action-url');

    if ($minicart.find('.js-hk-line-item').length === 0) return;

    $.get(hkActionURL, function (data) {
        if (!data.error) return;

        $minicart.find('.js-express-checkout-btns').remove();
        $minicart.find('.checkout-btn').attr('disabled', 'disabled').addClass('disabled');

        data.unavailableHkProducts.forEach(function (uuid) {
            $minicart.find(`.product-line-item[data-hkuuid="${uuid}"] .hk-unavailable-block`).removeClass('d-none');
        });
    });
}

module.exports = {
    showUnavailabeMsgHK: showUnavailabeMsgHK,
    minicart: minicart
};
