var slickConfigs = require('org/config/slickConfigs');
var carouselHelper = require('org/utils/carouselHelper');

$(document).ready(function () {
    if (window.innerWidth < 1024) {
        $('.header-top-banner').not('.slick-initialized').slick(slickConfigs.headerTopBanner);
    }

    $(window).resize(function () {
        if (window.innerWidth < 1024) {
            if (!$('.header-top-banner').hasClass('slick-initialized')) {
                $('.header-top-banner').slick(slickConfigs.headerTopBanner);
            }
        } else if ($('.header-top-banner').hasClass('slick-initialized')) {
            $('.header-top-banner').slick('unslick');
        }
    });

    $('.home-jewelry-carousel').not('.slick-initialized').slick(slickConfigs.jewelryCarousel);
    $('.pdp-product-carousels').not('.slick-initialized').slick(slickConfigs.pdpRecommendations);
    $('.hp-product-carousels').not('.slick-initialized').slick(slickConfigs.hpRecommendations);
    $('.store-image').not('.slick-initialized').slick(slickConfigs.storeDeatil);

    $('.home-reviews-carousels').not('.slick-initialized').slick(slickConfigs.homepageReviewsCarousel);
    $('.gifts-birthstone-carousel').not('.slick-initialized').slick(slickConfigs.giftsBirthstoneCarousel);
    $('.plp-category-carousel').not('.slick-initialized').slick(slickConfigs.plpCategoryCarousel);
    $('.home-shop-by-shape').not('.slick-initialized').slick(slickConfigs.hpShopbyShape);
    $('.home-nine-centered-mobile').not('.slick-initialized').slick(slickConfigs.hpNineCentered);
    $('.home-nine-centered-two-mobile').not('.slick-initialized').slick(slickConfigs.hpNineCenteredTwoMobile);
    $('.home-nine-centered-three-mobile').not('.slick-initialized').slick(slickConfigs.hpNineCenteredThreeMobile);
    $('.home-six-centered-mobile').not('.slick-initialized').slick(slickConfigs.hpSixCentered);
    $('.home-five-centered-mobile').not('.slick-initialized').slick(slickConfigs.hpFiveCentered);
    $('.home-four-centered-mobile').not('.slick-initialized').slick(slickConfigs.hpFourCentered);
    $('.home-four-centered-one-mobile').not('.slick-initialized').slick(slickConfigs.hpFourCenteredOneMobile);
    $('.home-visual-nav-carousel').not('.slick-initialized').slick(slickConfigs.hpThreeHalfOneMobile);

    const $promoBannerContent = $('.promo-banner-message-content');
    const dataAttrValue = $promoBannerContent.attr('data-attr');

    if (dataAttrValue && dataAttrValue !== 'null') {
        slickConfigs.promoBannerMessage.autoplaySpeed = dataAttrValue * 1000;
    }

    carouselHelper.processCarouselAccessibility($promoBannerContent);
    $promoBannerContent.not('.slick-initialized').slick(slickConfigs.promoBannerMessage);

    var pdpContentSectionConfig = Object.assign({}, slickConfigs.pdpContentSection);
    $('.pdp-content-section-carousel').each(function () {
        var $this = $(this);
        pdpContentSectionConfig.responsive[0].settings.slidesToShow = ($this.find('.pdp-content-section-block').length > 2) ? 3 : 2;
        $this.not('.slick-initialized').slick(pdpContentSectionConfig);
    });

    var mutationObserver = new MutationObserver(function (mutationsRecord) {
        mutationsRecord.forEach(function (mutationRecord) {
            mutationRecord.addedNodes.forEach(function (node) {
                var $node = $(node);

                if ($node.hasClass('pdp-product-carousels__wrapper') || ($node.find('.pdp-product-carousels__wrapper').length > 0)) {
                    $node.find('.pdp-product-carousels').not('.slick-initialized').slick(slickConfigs.pdpRecommendations);
                }
                if ($node.hasClass('search-carousels__wrapper') || ($node.find('.search-carousels__wrapper').length > 0)) {
                    $node.find('.search-carousels').not('.slick-initialized').slick(slickConfigs.searchRecommendations);
                }
                if ($node.hasClass('hp-product-carousels__wrapper') || ($node.find('.hp-product-carousels__wrapper').length > 0)) {
                    $node.find('.hp-product-carousels').not('.slick-initialized').slick(slickConfigs.hpRecommendations);
                }
            });
        });
    });

    var recommendationsBlock = document.querySelector('.recommendations');
    var siteSearchBlockMob = document.querySelector('.search-mobile .site-search');
    var siteSearchBlockFull = document.querySelector('.full-width .site-search');

    if (recommendationsBlock) {
        mutationObserver.observe(recommendationsBlock, { subtree: true, childList: true });
    }
    if (siteSearchBlockMob) {
        mutationObserver.observe(siteSearchBlockMob, { subtree: true, childList: true });
    }
    if (siteSearchBlockFull) {
        mutationObserver.observe(siteSearchBlockFull, { subtree: true, childList: true });
    }

    $(window).on('einstein-ready', function () {
        $('.cart-products-carousels').not('.slick-initialized').slick(slickConfigs.cartRecommendations);
    });
});
