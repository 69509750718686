'use strict';

var scrollAnimate = require('base/components/scrollAnimate');
var device = require('../utils/device');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if ($('.email-signup-message').length === 0) {
        $('body').append(
           '<div class="email-signup-message"></div>'
        );
    }
    $('.email-signup-message')
        .append('<div class="email-signup-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.email-signup-message').remove();
        button.removeAttr('disabled');
    }, 3000);
}

/**
 * Displays error message on email subscription
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayError(data, button) {
    $.spinner().stop();
    $('.invalid-email').remove();

    $('.subscribe-error')
    .append('<div class="invalid-feedback d-block invalid-email">' + data.msg + '</div>');

    setTimeout(function () {
        $('.email-signup-message').remove();
        button.removeAttr('disabled');
    }, 3000);
}


/**
 * Updates footer aria roles and toggle for desktop
 */
function disableFooterARIAonDesktop() {
    $('#footercontent .footer-item button.title').addClass('d-lg-none');
    $('#footercontent .footer-item button.title').each(function () {
        $('<h2 class="title d-none d-lg-block">' + $(this).html() + '</h2>').insertAfter($(this));

        if (device.desktop()) {
            $(this).removeAttr('aria-expanded data-toggle');
        }
    });
}

/**
 * Handles user's signup by email
 */
function subscribeEmail() {
    var url = $(this).data('href');
    var button = $(this);
    var emailId = $('input[name=hpEmailSignUp]').val();
    var csrfToken = button.closest('form').find('input[name="csrf_token"]');
    var csrfTokenValue = csrfToken.length > 0 ? csrfToken.val() : '';
    $.spinner().start();
    $(this).attr('disabled', true);
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: {
            emailId: emailId,
            csrf_token: csrfTokenValue
        },
        success: function (data) { // eslint-disable-line
            // displayMessage(data, button);
            if (data.encodedEmail) {
                window.heap.identify(data.encodedEmail);
            }
            if (data.error) {
                displayError(data, button);
            } else {
                window.location.href = 'https://cloud.emails.helzberg.com/footersignup';
            }
        },
        error: function (err) {
            displayMessage(err, button);
        }
    });
}

module.exports = function () {
    $('.search-show .search-link, .search-close').on('click', function (e) {
        e.preventDefault();
        $('.reset-button').trigger('click');
        $('.popover').removeClass('show');
        $('.search-mobile').toggleClass('d-none');
        var $focusField = $('.search-mobile').find('.search-field');
        $focusField.focus();
    });

    $('.back-to-top').click(function () {
        scrollAnimate();
    });

    $('body').on('click tap touchstart mouseover', '.camera-upload .camera-link', function (e) {
        e.preventDefault();
        $('.popover').removeClass('show');
        $('.camera-upload .popover').toggleClass('show');
    });

    $('body').on('mouseleave', '.camera-upload', function (e) {
        e.preventDefault();
        $('.camera-upload .popover').removeClass('show');
    });

    $('body').on('click tap touchstart', '.camera-upload .popover .close', function (e) {
        e.preventDefault();
        $('.camera-upload .popover').removeClass('show');
    });

    var tooltipOptions = {
        offset: '0, 10px'
    };

    $('[data-toggle="tooltip"]').tooltip(tooltipOptions);

    $('.subscribe-email').on('click', function (e) {
        e.preventDefault();

        var $form = $(this).closest('form');
        var $recaptcha = $form.find('[name="g-recaptcha-response"]');
        var context = this;
        if ($recaptcha.length && 'grecaptcha' in window) {
            var siteKey = $recaptcha.data('sitekey');
            var action = $form.find('[name="g-recaptcha-action"]').val();
            window.grecaptcha.execute(siteKey, { action: action }).then(function (token) {
                $recaptcha.val(token);
                subscribeEmail.call(context, e);
            });
        } else {
            subscribeEmail.call(context, e);
        }
    });

    $('a.skip').on('click', function (e) {
        const href = $(e.target).attr('href');
        if (href === '#maincontent') {
            $('body').find('a, [role="button"], button').not('.skip').first()
                .trigger('focus');
        } else if (href === '#footercontent') {
            $('footer').find('a, [role="button"], button').first()
                .trigger('focus');
        }
    });

    disableFooterARIAonDesktop();
};
